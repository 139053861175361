import JustValidate from 'just-validate/dist/js/just-validate.min';
import { isReCaptchaValid, removeRecaptchaError, setRecaptchaError } from './ReCaptchaActions';
import HystModal from 'hystmodal';
import { regExpEmail } from '../../../../../../Common/Resources/src_front/js/helper-functions/isValidMail';

export const initContactForm = () => {
    const formSelector = '[data-contact-us-form]';
    const form = document.querySelector(`${formSelector}`);
    if (!form) return;

    const modalContactForm = new HystModal({
        catchFocus: true,
        waitTransitions: true,
        closeOnEsc: false,
    });

    const formAction = new window.JustValidate(`${formSelector}`, {
        rules: {
            email: {
                required: true,
                email: true,
                strength: {
                    custom: regExpEmail,
                },
            },
            subject: {
                required: true,
            },
            text: {
                required: true,
            },
        },

        messages: {
            email: 'Field filled incorrectly',
        },

        submitHandler: function (form, values, ajax) {
            if (isReCaptchaValid()) {
                removeRecaptchaError(`${formSelector}`);
            } else {
                setRecaptchaError(`${formSelector}`);
                return;
            }

            ajax({
                url: form.getAttribute('action'),
                method: 'POST',
                data: values,
                async: true,
                callback: function (response) {
                    resetForm(form);
                    modalContactForm.open('[data-modal-success]');
                },
                error: function (response) {
                    modalContactForm.open('[data-modal-error]');
                },
            });
        },

        invalidFormCallback: function (errors) {
            if (errors && !isReCaptchaValid()) {
                setRecaptchaError(`${formSelector}`);
            }
        },
    });

    function resetForm(form) {
        grecaptcha.reset();
        form.reset();
        removeRecaptchaError(`${formSelector}`);
    }
};
